import { withAuth } from '@/api/request.js'

const request = withAuth()

export default () => {
  return request({
    data: {
      query: `
        {
          getEntityClients {
            id
		    firstName
		    lastName
		    avatarUrl
		    accounts {
			  id
			  accountNo
			  availableBalance
			  currency
			  designation
			  savingsProductName
			  parentAccountId
			  status
			  subStatus
		    }
		    cards {
			  id
			  productName
			  status
			  expiresOn
			  network
			  primaryAccountNumber
		    }
		    parentId
		    identifiers {
			  id
			  documentKey
			  documentType {
				id
				identifier
				label
				position
				description
			  } 
		    }
		    users {
			  id
			  firstName
			  lastName
			  mobile
			  roles {
				id
				name
			  }
		    }
		    clientTypes {
			  id
			  label
			  description
			  identifier
			  position
		    }
		    submittedOn
		    status
          }
        }
      `
    }
  })
}
