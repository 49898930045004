import { restApi, withAuth } from '@/api/request.js'
import { clientBulkUploadAdapter } from '@/api/adapters/clients/clientBulkUploadAdapter'

const request = withAuth()
const restRequest = restApi()

const createClientAccess = (payload) => {
  const identifier = payload.documentTypeId && (payload.documentKey || payload.documentDescription) ? `identifiers: [{
     documentTypeId: ${payload.documentTypeId}
     status: Active
     ${payload.documentKey ? 'documentKey: "'+payload.documentKey.replaceAll('-', '')+'"' : ''}
     ${payload.documentDescription ? 'description: "'+payload.documentDescription+'"' : ''}
  }]` : ''
  let addresses = '[]'
  if(payload.countryId) {
    addresses = `[{
      countryId: ${payload.countryId}
      city: "${payload.city}"
      addressTypeId: ${payload.addressTypeId}
      ${payload.stateProvinceId ? 'stateProvinceId: ' + payload.stateProvinceId : ''}
      postalCode: "${payload.postalCode}"
      addressLine1: "${payload.addressLine1}"
      addressLine2: "${payload.addressLine2}"
    }]`
  }
  return request({
    data: {
      query: `mutation {
        createClientAccess(
          input: {
            firstname: "${payload.firstname}"
            lastname: "${payload.lastname}"
            ${identifier}
            genderId: ${payload.genderId}
            officeId: ${payload.officeId} 
            emailAddress: "${payload.email}"
            dateOfBirth: "${payload.dateOfBirth}"
            addresses: ${addresses}
            designation: "${payload.designation}"
            mobileNo: "${payload.mobile.mobileNo.replace(/\s/g, '')}"
            mobileCountryCode: "${payload.mobile.mobileCountryCode.replace(/\s/g, '')}"
            roles: ${JSON.stringify(payload.roles)}
            clientTypes: ${JSON.stringify(payload.clientTypes)}
            locale: "en"
            dateFormat: "yyyy-MM-dd"
          }
        ) {
          user {
            id
            firstName
            lastName
          }
          client {
            id
            firstName
            lastName
          }
        }
      }`
    }
  })
}

const createClientsBundle = (payload) => {
  return request({
    data: {
      query: `mutation {
        createClientsBundle(
          input: ${clientBulkUploadAdapter(payload)}
        )
      }`
    }
  })
}

const linkExistingClient = (clientId) => {
  return request({
    data: {
      query: `mutation {
        linkClientAccess(clientPersonId: ${clientId}) {
          user {
            id
          }
          client {
            id
          }
        }
      }`
    }
  })
}

const dictionariesForClient = () => {
  return request({
    data: {
      query: `
        {
          getClientAddressTypes { 
            id 
            label 
          }
          getCountries {
            id
            label
            identifier
          }
          getClientIdentifierDocumentTypes {
            id
            label
            identifier
            position
          }
          getGenders {
            id
            label
          }
          Offices {
		    select {
			  id
			  name
		    }
	      }
	      CodeValues(where: { code: { name: { EQ: "ClientType" } } }) {
	        select {
	          id
	          label
	        }
	      }
        }
      `
    }
  })
}

const dictionariesForBulkUpload = () => {
  return request({
    data: {
      query: `
        {
          getClientIdentifierDocumentTypes {
            id
            label
          }
          getGenders {
            id
            label
          }
          CardProducts {
            select {
              id
              name
            }
          }
          getSSURoles {
            id
            name
          }
	      CodeValues(where: { code: { name: { EQ: "ClientClassification" } } }) {
	        select {
	          id
	          label
	        }
	      }
	      Offices {
		    select {
			  id
			  name
		    }
	      }
        }
      `
    }
  })
}

const getClientTypes = () => {
  return request({
    data: {
      query: `
        {
	      CodeValues(where: { code: { name: { EQ: "ClientType" } } }) {
	        select {
	          id
	          label
	        }
	      }
        }
      `
    }
  })
}

const deactivateClient = (payload) => {
  return request({
    data: {
      query: `mutation {
        deactivateClient(
          clientPersonId: ${payload.clientPersonId}
          input: {
            reason: "${payload.reason}"
          }
        ) {
          clientEntityId
          clientPersonId
          }
      }`
    }
  })
}

const uploadAvatar = (clientId, file) => {
  const data = new FormData()
  data.append('file', file)
  return restRequest({
    method: 'post',
    url: `/self/clients/${clientId}/images`,
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  })
}

const bulkExcelUpload = (clientId, file) => {
  const data = new FormData()
  data.append('file', file)
  return restRequest({
    method: 'post',
    url: `/self/clients/${clientId}/uploadtemplate`,
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  })
}

export {
  createClientAccess,
  dictionariesForClient,
  dictionariesForBulkUpload,
  getClientTypes,
  linkExistingClient,
  uploadAvatar,
  bulkExcelUpload,
  createClientsBundle,
  deactivateClient
}
