const savingsTransaction = `
  fragment savingsTransaction on SavingsAccountTransaction {
    account {
      id
      currency {
        code
      }
    }
    amount
    createdAt
    typeOf
    paymentDetail {
      reference
    }
  }
`

const subsidiaryAccountTransaction = `
  fragment subsidiaryAccountTransaction on SubsidiaryAccountTransaction {
    account {
      id
      currency {
        code
      }
    }
    amount
    createdAt
    typeOf
    paymentDetail {
      reference
      cardAuthorization {
        id
        merchant
        amountDetails {
          cashBackAmount
          exchangeRate
          feeAmount
          localCurrency
          localCurrencyAmount
        }
      }
      paymentType {
        name
      }
    }
  }
`

const savingsTransactionDetails = `
  fragment savingsTransactionDetails on SavingsAccountTransaction {
    account {
      accountNumber
    }
    paymentDetail {
      creditor {
        name
        identifier
        address
        country
        city
        postalCode
      }
      debtor {
        name
        identifier
        address
        country
        city
        postalCode
      }
      cardAuthorization {
        merchant
        amountDetails {
          cashBackAmount
          exchangeRate
          feeAmount
          localCurrency
          localCurrencyAmount
        }
      }
      paymentType {
        name
      }
    }
  }
`

const subsidiaryAccountTransactionDetails = `
  fragment savingsTransactionDetails on SubsidiaryAccountTransaction {
    paymentDetail {
      cardAuthorization {
        merchant
        amountDetails {
          cashBackAmount
          exchangeRate
          feeAmount
          localCurrency
          localCurrencyAmount
        }
      }
      paymentType {
        name
      }
    }
  }
`

const loanTransaction = `
fragment loanTransaction on LoanTransaction {
  typeOf
  valueDate
  amount
  outstandingLoanBalance
  penaltyChargesPortion
  feeChargesPortion
  interestPortion
  principalPortion
  loan {
    id
    productDetail {
      currency {
        code
      }
    }
  }
}
`

const getAccountTransaction = `
  fragment getAccountTransaction on UnifiedSavingsTransactionsData {
    total
    limit
    offset
    select {
      id
      amount
      typeOf
      createdAt
      subsidiaryAccountId
      account {
        id
        accountNumber
        currency {
         code
         displaySymbol
        }
      }
      paymentDetail {
        id
        reference
        paymentType {
          id
          name
        }
        creditor {
          name
          identifier
        }
        debtor {
          name
          identifier
        }
        merchant
      }
      transactionSubType
      transactionType
      type
    }
  }
`

const cardAuthorizationData = `
  fragment cardAuthorizationData on TxnCardAuthorizationData {
    id
    expiryDate
    currency
    merchant
    amount
    authType
    createdAt
    status
    transaction
    transactionType
    amountDetails {
      feeAmount
      localCurrency
      cashBackAmount
      exchangeRate
      localCurrencyAmount
    }
  }
`

const debtorCreditorData = `
  fragment debtorCreditorData on TxnDebtorCreditorData {
    name
    identifier
    address
    city
    country
    postalCode
  }
`


export {
  savingsTransaction,
  savingsTransactionDetails,
  loanTransaction,
  subsidiaryAccountTransaction,
  subsidiaryAccountTransactionDetails,
  getAccountTransaction,
  cardAuthorizationData,
  debtorCreditorData
}
