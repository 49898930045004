import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index.js'
import routes from './routes/index.js'
import enabledComponents from '@/config/enabled.js'
import { clearLocalStorage } from '@/utils/helpers.js'
import EventBus from '@/utils/eventBus'
import i18n from '@/plugins/i18n.js'
import { SecureLocalStorageService } from '@/services/SecureLocalStorageService'

Vue.use(Router)

const tempRoutes = routes || []

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      meta: {
        public: false
      }
    }, ...tempRoutes
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const clientName = process.env.VUE_APP_CLIENT

  if (clientName) {
    document.title = `${clientName.charAt(0).toUpperCase() + clientName.slice(1)} Web Banking`
  }

  const authenticated = store.state.user.authenticated
  const required2FA = store.state.user.required2FA
  const isPasswordExpired = store.state.user.isPasswordExpired
  const onlyLoggedOut = to.matched.some(record => record.meta.onlyLoggedOut)
  const isPublic = to.matched.some(record => record.meta.public)
  const isRequireAcceptTermsConditions = store.state.user.isRequireAcceptTermsConditions

  if (enabledComponents && !enabledComponents.includes(to.name)) return next(false)

  if (to.meta.permissions && to.meta.permissions.length) {
    if (!store.getters['user/userHasPermissions'](to.meta.permissions)) {
      EventBus.$emit('notify-with-message', i18n.t('views.errorCodes.notEnoughPermissions'), { flushQueue: true, status: 'error'})
      return next('/')
    }
  }

  if (!isPublic && !authenticated) {
    return next('/login')
  }

  if (to.name === 'termsConditions' && !isRequireAcceptTermsConditions ) {
    return next('/login')
  }

  if (to.name === 'login' && from.name === 'otp') {
    const secureLocalStorage = new SecureLocalStorageService()

    clearLocalStorage()
    secureLocalStorage.clear()
  }

  if (to.name === 'otp' && !required2FA) {
    return next('/login')
  }

  if (to.name === 'resetPassword' && !isPasswordExpired) {
    return next('/login')
  }

  if (authenticated && onlyLoggedOut) {
    return next('/')
  }

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    return next({ name: to.name, query: from.query, params: to.params })
  }

  next()
})

function hasQueryParams (route) {
  return !!route.query.tenantIdentifier
}

export default router
