import { withAuth } from '@/api/request.js'
import {
  currentAccount,
} from '@/api/fragments/index.js'
const request = withAuth()

export const getDashboardSavingsAccounts = () => {
  return request({
    data: {
      query: `
        {
          getSavingsAccounts(includeAllSubAccounts: true, includeMainAccount: true, includeClientSubAccounts: true) {
            ...currentAccount
          }
        }
        ${currentAccount}
      `
    }
  })
}
