import vuetify from 'vuetify/es5/locale/en.js'
import validators from './validators.js'
import { getClientSpecificFile } from '@/utils/clientSpecificHelpers'

const clientSpecificTranslations = getClientSpecificFile('en', 'translations').default

export default {
  $vuetify: vuetify,
  validators,
  clientSpecificTranslations,
  apiResponses: {
    errors: {
      invalidGrant: 'Incorrect login details',
      timeout: 'Request timeout',
      failedToConnect: 'The was a problem connecting to the server. Please try again.',
      noPermissions: 'User has no sufficient permissions to log into web banking',
      sessionExpired: 'Session has expired',
      card: {
        action: {
          reach: {
            limit: 'Replacing card reach limit'
          }
        }
      },
      physical: {
        card: {
          status: {
            invalid: 'Physical card is not activated'
          }
        }
      }
    }
  },
  transactionType: {
    0: 'Invalid',
    1: 'Deposit',
    2: 'Withdrawal',
    3: 'Interest Posting',
    4: 'Withdrawal Fee',
    5: 'Annual Fee',
    6: 'Waive Charges',
    7: 'Pay Charge',
    8: 'Dividend Payout',
    12: 'Initiate Transfer',
    13: 'Approve Transfer',
    14: 'Withdraw Transfer',
    15: 'Reject Transfer',
    16: 'Written Off',
    17: 'Overdraft Interest',
    18: 'Withhold Tax',
    19: 'Escheat',
    20: 'Amount Hold',
    21: 'Amount Release',
    30: 'Funds allocation',
    31: 'Funds deallocation',
    transfer: 'Transfer between account',
    pending: 'Pending Transaction'
  },
  loanTransactionType: {
    0: 'Invalid',
    1: 'Disbursement',
    2: 'Repayment',
    3: 'Contra',
    4: 'Waive interest',
    5: 'Repayment at disbursement',
    6: 'Writeoff',
    7: 'Marked for rescheduling',
    8: 'Recovery repayment',
    9: 'Waive charges',
    10: 'Accrual',
    12: 'Initiate transfer',
    13: 'Approve transfer',
    14: 'Withdraw transfer',
    15: 'Reject transfer',
    16: 'Refund',
    17: 'Charge payment',
    18: 'Refund for active loan',
    19: 'Income posting'
  },
  transferType: {
    SWIFT: 'Swift',
    INTERNAL_TRANSFER: 'Internal'
  },
  components: {
    about: {
      close: 'Close',
      aboutUs: 'About Us',
      appVersion: 'Version : {appVersion}',
      buildVersion: 'Build : {buildVersion}',
      contactUs: 'Contact Us'
    },
    login: {
      title: 'Login',
      submitButton: 'Login'
    },
    menu: {
      dashboard: 'Dashboard',
      accounts: 'Accounts',
      transactions: 'Transactions',
      standingOrders: 'Standing orders',
      products: 'Products',
      cards: 'Cards',
      recipients: 'Recipients',
      communications: 'Messages',
      home: 'Home',
      transfer: 'Transfer',
      users: 'Users',
      employees: 'Employees',
      createTransfer: 'Create transfer',
      clients: 'Manage users',
    },
    toolBar: {
      profile: {
        dropdown: {
          userSettings: 'User settings',
          darkMode: 'Dark mode',
          logout: 'Logout',
          aboutUs: 'About us',
          accountManagement: 'Account management'
        },
        tooltip: 'Profile'
      }
    },
    loadingScreen: {
      loading: 'Getting ready'
    },
    dashboard: {
      intro: {
        overallAvailableBalance: 'Available balance',
        allocatedThisMonth: 'balance allocated this month',
        unallocatedBalance: 'unallocated balance',
        balanceTrend: `This month and last month's balance trend`,
        overallBalance: 'Total balance',
        lastLogin: 'Last logged in',
        date: 'Date',
        browser: 'Browser',
        location: 'Location',
        expenseThisMonth: 'expense this month',
        chart: {
          thisMonth: 'this month',
          lastMonth: 'last month'
        },
        monthly: 'Your last four weeks',
        week: 'Week'
      }
    },
    transactions: {
      searchFilters: {
        search: 'Search',
        fromDate: 'Date from',
        toDate: 'Date to',
        moreFilters: 'More filters',
        clear: 'Clear',
        applyFilter: 'Apply filter',
        generateStatement: 'Generate statement',
        generateStatementFile: 'Generate statement file',
        generateStatementFileNote: 'Select date range for your account statement of transactions.',
        datePicker: {
          toDate: 'To',
          fromDate: 'From'
        },
        invalidDateFormat: 'Please enter a valid date format',
        month: 'Month',

      },
      list: {
        account: 'Account',
        noData: 'There are no activities starting past month on your account. Enter accounts section to see all of your past transactions',
        noSelectAcc: 'You must select at least one account',
        loading: 'Loading transactions...',
        loadMore: 'Load more',
      },
      detail: {
        receiver: 'Receiver',
        txDetail: 'Transaction details',
        accDetails: 'Account details',
        nameAndAddress: 'Name and address',
        name: 'Name',
        subject: 'Subject',
        createdAt: 'Created at',
        booking: 'Booking',
        accountName: 'Name',
        accountAddress: 'Address {lineNumber}',
        type: 'Type',
        amount: 'Amount',
        fromAcc: 'From account',
        merchantDetails: 'Merchant details',
        merchant: 'Merchant',
        merchantId: 'Merchant ID',
        mcc: 'MCC',
        country: 'Country',
        cardTransaction: 'Card transaction',
        cardAuthorization: 'Card authorization',
        amountDetails: 'Amount details',
        localCurrency: 'Local currency',
        localCurrencyAmount: 'Local currency amount',
        exchangeRate: 'Exchange rate',
        fee: 'Fee',
        declined: 'Declined',
      },
      accountSelection: {
        includeClosedAccounts: 'Include closed accounts'
      },
      statementsModal: {
        statementsOfTransactions: 'Statements of Transactions',
        downloadPdf: 'Download PDF'
      }
    },
    accounts: {
      create: {
        header: 'Add organization account'
      },
      list: {
        savings: {
          title: 'Savings accounts',
          balance: 'Available balance'
        },
        loan: {
          title: 'Loan accounts',
          balance: 'Loan amount'
        }
      },
      documents: {
        title: 'Documents',
        downloadBankLetter: 'Download bank letter',
        downloadWireDetails: 'Download ACH & Wire details'
      },
      lastTransactions: {
        title: 'Latest transactions'
      },
      showAllTransactions: 'Show all transactions',
      transactionsHistory: 'Transactions history',
      twoMonthsNote: 'Here you can see the two months of the activity. Enter transactions history to see all of your transactions.',
      headers: {
        savings: {
          balance: 'Available balance'
        },
        loan: {
          balance: 'Loan amount'
        }
      },
      details: {
        title: 'Account details',
        branch: 'Branch',
        openingDate: 'Opening Date',
        accountNo: 'Account Number',
        bankRoutingCode: 'Bank Routing Code',
        holder: 'Account Holder',
        status: 'Account Status',
        type: 'Account Type',
        accountCurrency: 'Account Currency',
        overdraftAmount: 'Ovedraft Amount',
        interestRate: 'Interest Rate',
        availableBalance: 'Available Balance',
        totalBalance: 'Total Balance',
        iban: 'IBAN',
        swiftCode: 'Swift Code',
        savingsAccountStatusType: {
          active: 'Active'
        },
        accountType: {
          individual: 'Individual'
        },
        productName: 'Product Name',
        loanAmount: 'Loan Amount',
        diburseDate: 'Disbursement date',
        matureOn: 'Matures on',
        fees: 'Fees',
        noOfRepay: 'No. of repayments',
        penalties: 'Penalties',
        nickname: 'Nickname',
        copiedToClipboard: 'Copied to clipboard!'
      },
      savingAccountStatus: {
        100: 'Created',
        200: 'Approved',
        300: 'Activated',
        400: 'Withdrawn',
        500: 'Rejected',
        600: 'Closed',
        blocked: 'Blocked',
        activated: 'Activated',
        blockDebit: 'Block Debit',
        blockCredit: 'Block Credit'
      },
      totalBalance: 'Total balance',
      unallocatedBalance: 'Unallocated balance',
    },
    transfers: {
      buttons: {
        back: 'back to edit',
        confirm: 'confirm transfer',
        cancel: 'cancel',
        submit: 'Review Transfer'
      },
      details: {
        accountSelection: {
          title: 'Debit Money From Account',
          description: 'Money will be debitted from the selected account to transfer to the receiver ( creditor )'
        },
        recipientAccount: {
          title: 'Recipient Account',
          description: 'Enter recipient account number or select one of your accounts ( to make transfer to your other account )',
          account: {
            yourAccount: 'Your Accounts'
          }
        },
        transferDetail: {
          title: 'Transfer Details',
          description: 'Currency of the transfer depends on the debit account. If your account is in USD, your transfer will only operate within that currency'
        },
        recipientDetail: {
          title: 'Recipient Name & Address',
          description: 'Information regarding recipient and their residency',
        },
        creditorAgent: {
          title: 'Bank Information',
          description: 'Information regarding the recipient bank'
        },
        transferDocument: {
          title: 'Transfer Document',
          description: 'Optional Attachment: Include Additional Documents or Files such as Wire request etc. (If Applicable)'
        }
      },
      transferTypes: {
        internal: {
          title: 'Internal Transfer',
          description: 'transfer within the same bank',
        },
        ach: {
          title: 'ACH Transfer',
          description: 'Usually the money will be available within the same account in 3 days'
        },
        wire: {
          title: 'WIRE Transfer',
          description: 'Nearly instant transfer, the money should arrive on the same day'
        },
        swift: {
          title: 'SWIFT Transfer',
          description: 'International transfer using SWIFT network'
        }
      },
      recent: {
        recentTransfers: 'Create new transfer from recent ones',
        noRecentTransfer: 'No recent transfers'
      },
      recipients: {
        recipients: 'Create new transfer to one of your beneficiaries'
      },
      internal: 'Internal',
      domestic: 'Domestic',
      initiate: 'Initiate Transfer',
      confirmation: {
        title: 'Transfer Confirmation',
        submitTransfer: 'Submit Transfer',
        recipient: {
          subtitle: 'Recipient',
          branch: 'Account Number - Branch',
          accountHolder: 'Account Holder',
          iban: 'IBAN'
        },
        sender: {
          account: 'From account'
        },
        details: {
          firstName: 'First Name',
          lastName: 'Last Name',
          nickName: 'Nick Name',
          subtitle: 'Transfer Details',
          account: 'Account',
          type: 'Transfer Type',
          amount: 'Amount',
          availableBalance: 'Available Balance',
          title: 'Transfer Title',
          reference: 'Transfer Description'
        }
      },
      form: {
        title: 'Details',
        agree: 'Agree to T&C',
        amount: 'Amount',
        subject: 'Subject',
        reference: 'Reference',
        optional: 'Optional',
        subjectLength: 'Must be less than 120 characters',
        subjectRequired: 'Subject is required',
        amountRequired: 'Amount is required',
        termAgree: 'You must agree to T&C',
        submit: 'Submit',
        clear: 'Clear',
        accountSelectRule: 'Please select Account and Transfer Type'
      },
      transferType: {
        title: 'Select transfer type',
        internalTransfer: 'Internal transfer to:',
        domesticTransfer: 'Domestic transfer to:',
        sepaTransfer: 'SEPA transfer to:',
        changeType: 'Change Type'
      },
      accountSelection: {
        title: 'From account',
        changeAccount: 'Change Account',
        debtor: 'From account'
      },
      searchList: {
        makeTransfer: 'Make a transfer to',
        noMatch: 'We haven\'t found any matching recipient or transaction for {user}',
        search: 'Search from recent transfers / Enter the recipient name',
        wouldYouLike: 'Would you like to create a new transfer?',
        newTransfer: 'New Transfer'
      },
      new: {
        specialCharactersAllow: 'Allowed special characters: / ? : ( ) . , \' + -',
        form: {
          enterRecipientDetails: 'Enter recipient details',
          amount: 'Enter amount to transfer',
          subject: 'Subject',
          next: 'Next',
          requiredRule: 'Field is required',
          limitChar: 'Cannot be longer than 120 characters',
          minValue: 'Amount must be greater than 0',
          update: 'Update'
        },
        transferTypes: {
          recipientName: 'Recipient name',
          accountNo: 'Account number',
          iban: 'IBAN',
          bankCode: 'Bank Code',
          bic: 'BIC',
          email: 'Email'
        },
        recipient: {
          required: 'Field is required',
          minLength: 'Must be more than 2 characters'
        },
        accountSelection: {
          title: 'Select account to transfer money from'
        },
        preview: {
          updateRecipient: 'Update recipient data'
        },
        summaryPage: {
          confirmNewTransfer: 'Confirm new transfer',
          remainBalance: 'At your account will remain',
          debitBalance: 'Your account will be debited with',
          confirm: 'Confirm Transfer',
          exceededBalance: 'There is not enough money on your account to make this transfer. You have {accountAmount} on your account.'
        },
        successPage: {
          success: 'Your transfer to {recipientName} was successful',
          transferred: 'transferred',
          noAdditionalCharges: 'no additional charges were applied',
          returnToDashboard: 'return to dashboard',
          saveAsPdf: 'save as pdf',
          saveAsRecipient: 'save as Recipient'
        },
        panel: {
          title: 'New transfer',
          recipientDetailsInfoTitle: 'Recipient details',
          transferDetailsInfoTitle: 'Transfer details',
          debitAccountInfoTitle: 'Debit account',
          transferTypeInfoTitle: 'Transfer type'
        },
        paymentType: {
          regularTransferTitle: 'Regular transfer',
          instantTransferTitle: 'Instant transfers'
        }
      },
      creation: {
        domesticType: {
          recipientAddressTitle: 'Recipient address',
          fullName: 'Recipient full name',
          nickname: 'Nickname',
          accountNo: 'Account number',
          sortCode: 'Sort code',
          routingNumber: 'Routing number',
          BICOrSWIFT: 'BIC / SWIFT',
          country: 'Country',
          city: 'City',
          state: 'State',
          postalCode: 'Postal code',
          zipCode: 'Zip code',
          streetAddress: 'Address line {lineNumber}',
          accountType: 'Account type'
        },
        emailType: {
          nickname: 'Nickname',
          fullName: 'Recipient full name',
          email: 'Recipient email'
        },
        sepaType: {
          nickname: 'Nickname',
          fullName: 'Recipient full name',
          iban: 'IBAN',
          bic: 'BIC'
        },
        minLength: 'Must be {length} characters or more',
        maxLength: 'Must be less than {length} characters',
        minMaxLength: 'Need to be between {minLength} - {maxLength} characters',
        sameLength: 'Must be {length} characters',
        required: 'This field is required',
        invalidEmail: 'Invalid email',
        mustBeNumbers: 'Inputs must be numbers',
        success: {
          message: 'Your new recipient {nickname} was successfully stored',
          accountNo: 'Account No.',
          sortCode: 'Sort code',
          iban: 'IBAN',
          bic: 'BIC',
          email: 'Email',
          returnToDashboard: 'return to dashboard'
        },
        bankName: 'Bank name',
        bankAddress: 'Bank address',
        bankAddressLine: 'Bank address line',
        bankCountry: 'Bank country'
      }
    },
    shared: {
      confirmation: {
        default: {
          title: 'Confirm your action with OTP',
          description: 'To verify your request, enter the token that was sent to your email address.',
          descriptionTOTP: 'To verify your request, enter token from two-factor app on your mobile device.',
          subDescription: 'You can cancel this OTP confirmation. Action will be cancelled.',
          confirm: 'Confirm',
          cancel: 'Cancel',
          fieldRequired: 'OTP is required'
        },
        submitTransfer: {
          title: 'Confirm new transfer',
          description: 'Verify your transfer. Enter token that was sent to your email address.',
          descriptionTOTP: 'Verify your transfer. enter token from two-factor app on your mobile device.',
          subDescription: 'You can cancel this confirmation, no new transfer will be created.'
        }
      },
      greetings: {
        night: 'Good night,',
        morning: 'Good morning,',
        afternoon: 'Good afternoon,',
        evening: 'Good evening,',
        whatTime: 'What time is it?'
      },
      messages: {
        noData: 'No data available',
        fieldRequired: 'This field is required',
        emailInvalid: 'Invalid Email',
        phoneNumberInvalid: 'Invalid phone number',
        pinLengthInvalid: 'PIN must be {length} digits',
        pinNotMatch: 'PIN not match',
        patternNotMatch: 'Invalid value.',
        zipCodeNoMatch: 'Zip code must be in format {format}',
        fileDragAndDrop: 'Drag and drop here or click to upload',
        fileSizeRestrictions: 'File size needs to be between 1 kB and {maxSize} MB'
      },
      dateFormat: {
        today: 'Today',
        yesterday: 'Yesterday'
      }
    },
    cards: {
      title: 'Cards',
      replacement: 'Replacement',
      renewedNotify: 'of your card is on the way. Once you will receive your new card please activate it.',
      expiredNotify: 'Your card has expired.',
      isFrozen: 'Card is currently frozen.',
      cardHolder: 'Card holder',
      confirmation: {
        viewPin: {
          title: 'Confirm view pin'
        },
        activate: {
          title: 'Confirm card activation'
        },
        blocked: {
          title: 'Confirm card block'
        }
      },
      status: {
        title: 'Manage card status',
        canActivatePhysicalCard: 'Remeber to activate your card only if you have received it.',
        activatePhysicalCard: 'Activate Physical Card',
        activatePhysicalCardLabel: 'Have you already received your physical card?',
        cardIsNotShipped: 'Once the physical card has been shipped to your address, the card activation will be available.',
        physicalCardIsAlreadyActive: 'Physical card is already active',
        unfreezeToActivatePhysicalCard: 'Physical card has been shipped. Card needs to be unfrozen to be able to activate.',
        replace: {
          title: 'Replace card',
          subtitle: 'Lost, stolen or damaged?'
        },
        damaged: {
          title: 'Report as damaged',
          subtitle: 'Your current card will remain active until activation of the new card'
        },
        reordered: {
          title: 'Reported as damaged',
          subtitle: 'Replacement to your damaged card has been ordered'
        },
        lostOrStolen: {
          title: 'Report as lost or stolen',
          subtitle: 'Lock your card to report as lost or stolen'
        }
      },
      security: {
        title: 'Security',
        onlinePayments: 'Online payments',
        contactlessPayments: 'Contactless payments',
        atmWithdrawals: 'ATM withdrawals - {action}',
        internationalPayments: 'International payments - {action}',
        changePin: {
          title: 'Reset your card PIN'
        },
        cardLimits: {
          title: 'Card limits',
          saveLimits: 'Save limits',
          edit: {
            title: 'Edit card limits',
            disabledReason: `Can not edit card limit when the card's status is not active`
          }
        }
      },
      details: {
        validTo: 'Valid To',
        validThru: 'valid',
        cvc: 'cvc',
        blocked: 'Card is blocked',
        block: 'Block card',
        terminate: 'Terminate card',
        freeze: {
          verb: 'Freeze card',
          title: 'Lock card',
          subtitle: 'Lock/Unlock your card temporarily'
        },
        tooltip: 'Applicable to virtual and physical cards at the same time',
        showPin: 'Show PIN',
        showDetails: 'View card details',
        hideDetails: 'Hide card details',
        activate: 'Activate Card',
        pin: {
          sec: 'sec',
          title: 'Card PIN'
        },
        unfreeze: 'Unfreeze',
      },
      limits: {
        note: {
          note: 'Note:',
          details: 'Your card spend is limited to default limits. You can define your own limits. By doing so default limits will be disabled, and only your limits will be in use.',
          extra: 'Deleting new limits will reset to default limits.'
        },
        title: 'Limits',
        cardPayments: 'Card payments',
        cashWithDrawals: 'Cash withdrawals',
        maxRule: 'Excess maximum amount',
        secondsLimit: 'Single limit',
        daysLimit: 'Daily limit',
        monthsLimit: 'Monthly limit',
        weeksLimit: 'Weekly limit',
        amountCannotBeHigher: 'Amount cannot be higher than {amount}',
        amountCannotBeLower: 'Amount cannot be lower than {amount}',
        limitCannotBeADecimalNumber: 'Limit cannot be a decimal number',
        limitCannotBeEqualToZero: 'Limit cannot be equal to 0',
        defaultLimit: 'default {amount}',
        cancel: 'Cancel',
        save: 'Save',
        value: 'Value',
        expiryDate: 'Expiry date',
        limitsSavedSuccessfully: 'Limits saved successfully'
      },
      regions: {
        title: 'Regions',
        blockedRegion: 'Blocked regions',
        cancel: 'Cancel',
        save: 'Save',
        success: 'Block regions updated'
      },
      mobileList: {
        selectCard: 'Select card'
      },
      notification: {
        msg: {
          cardGetOrdered: 'Your {cardProductName} order is being processed now. You will be notified when the status of your order is changed.',
          cardGetActivated: 'Your new {cardProductName} is ready to be activated. Once you will receive your card you can activate it and start using it.'
        },
        alert: {
          activatedSuccess: 'You have activated your new card'
        },
        btn: {
          activate: 'Activate your new card'
        }
      }
    },
    settings: {
      general: {
        languages: 'Languages',
        languagesDescription: 'Choose display language',
        dateFormat: 'Date Format',
        dateFormatDescription: 'Choose display date format',
        mainCurrency: 'Main Currency',
        mainCurrencyDescription: 'Choose main currency',
        information: 'Information',
        help: 'Help',
        accounts: 'Accounts',
        save: 'Save'
      },
      notification: {
        newOutgoingTransaction: 'New outgoing transaction',
        newOutgoingTransactionDescription: 'Change new outgoing transaction',
        newIncomingTransaction: 'New incoming transaction',
        newIncomingTransactionDescription: 'Change new incoming transaction',
        upcomingStandingOrder: 'Upcoming standing order',
        upcomingStandingOrderDescription: 'Change upcoming standing order',
        executionStandingOrder: 'Execution standing order',
        executionStandingOrderDescription: 'Change execution standing order',
        insufficientFundStandingOrder: 'Insufficient fund standing order',
        insufficientFundStandingOrderDescription: 'change insufficient fund standing order',
        newMonthlyStatement: 'New monthly statement',
        newMonthlyStatementDescription: 'Change new monthly statement',
        newMessage: 'New message',
        newMessageDescription: 'Change new message',
        save: 'Save'
      },
      security: {
        pinTitle: 'PIN',
        pinDescription: 'Change your PIN',
        currentPin: 'Current PIN',
        newPin: 'New PIN',
        confirmPin: 'Confirm PIN',
        changePinButton: 'Reset your card PIN',
        passwordTitle: 'Password',
        passwordDescription: 'Change password',
        currentPassword: 'Current Password',
        currentPasswordInfo: 'If you do not remember your current password, please log out and use reset password link at the login panel.',
        newPassword: 'Input your new password',
        newPasswordLast3: 'Password cannot be the same as one of your last 3 used passwords',
        confirmPassword: 'Confirm your new password',
        changePasswordButton: 'Change Password',
        passwordChanged: 'Password has been changed successfully',
        totpTitle: 'Two-Factor Authenticator',
        totpDescription: 'Increase your account security by enabling Two-Factor Authentication (2FA)',
        totpEnabled: 'You\'ve already enabled two-factor authentication using one time password authenticators. In order to register a different device, you must first disable two-factor authentication.',
        totpScanQr: 'Install an authenticator app such as Google, Microsoft or any other on your mobile device and use it to scan this QR code.',
        totpCantScanQr: 'Can\'t scan the code?',
        totpManualKey: 'To add the entry manually, provide the following details to the application on your phone.',
        totpManualKeyMobile: 'Open your authentication app and enter the code below.',
        totpKey: 'Key:',
        totpConfirmation: 'Confirm activation of 2FA with generated token',
        totpConfirm: 'Confirm activation',
        totpDisableStatus: 'Status: Disabled',
        totpEnable: 'Enable 2FA',
        totpDisable: 'Disable 2FA',
        token: 'Token',
        confirmWithtwoFactorApp: 'Confirm with two-factor app',
        disableTwoFactorAuthentication: 'Disable two-factor authentication?',
        invalidateRegisteredApplications: 'This will invalidate your registered applications.',
        doNotDisable: 'Do Not Disable',
        disableItForNow: 'Disable it for now',
        confirm: 'Confirm',
        recoveryCodesTitle: 'Two-factor Authentication Recovery codes',
        recoveryCodesDescription: 'Should you ever lose your phone or access to your one time password secret, each of these recovery codes can be used one time each to regain access to your account. Please save them in a safe place, or you will lose access to your account.',
        back: 'Back',
        downloadKey: 'Download Key',
        copiedSuccess: 'Copied',
        copiedFail: 'Cannot copy, please try again'
      },
      profiles: {
        personalInformation: 'Personal Information',
        users: {
          firstName: 'First name',
          lastName: 'Last name',
          username: 'Username',
          email: 'Email Address',
          mobile: 'Mobile Number'
        }
      }
    },
    communications: {
      all: 'All Messages',
      markAsResolved: 'Mark as resolved',
      status: 'Status',
      inProcess: 'In process',
      opened: 'Opened',
      closed: 'Closed',
      received: 'Received',
      download: 'Download',
      created: 'Created',
      inputMessage: 'Input message',
      itemsPerPage: 'Items per page:',
      noDataText: 'No data available',
      date: 'Date',
      statementOfAccount: 'Account statement',
      ofAccount: 'of account',
      fileName: 'File name',
      file: 'File',
      newCommunication: {
        header: 'Create new message',
        categoryLabel: 'Message category',
        categories: {
          asset: 'My assets',
          bug: 'Report a bug'
        },
        titleLabel: 'Message title',
        messageLabel: 'Message',
        attachFile: 'Attach files',
        send: 'Send message',
        required: 'This field is required',
        minChar: 'Min {minChar} characters'
      },
      emptyMessages: {
        title: 'You have no messages',
        body1: 'Your message box is empty.',
        body2: 'We will let you know once you have your first message.'
      },
      listOfAvailableStatements: 'List of available account statements'
    },
    support: {
      all: 'All Tickets',
      itemsPerPage: 'Items per page:',
      received: 'Received',
      status: 'Status',
      emptyMessages: {
        title: 'You have no support tickets',
        body1: 'Your support box is empty',
        body2: 'You will find your support tickets here'
      },
      inbox: 'Inbox'
    },
    recipients: {
      list: {
        search: 'Search',
        allRecipients: 'All recipients',
        accountNumber: 'Account number',
        bankCode: 'Bank code',
        nickname: 'Nickname',
        fullName: 'Full name',
        details: 'Details',
        actions: 'Actions'
      },
      details: {
        details: 'Details',
        sendMoney: 'Send Money',
        title: 'Recipients Details',
        transactionList: 'Transaction history of recipient',
        accountNo: 'Account Number',
        nickname: 'Nickname',
        fullName: 'Full Name',
        sortCode: 'Sort Code',
        deleteRecipient: 'Delete Recipient?',
        deleteRecipientDescription: 'Deleting recipient will permanently remove it from your recipient list',
        confirmDelete: 'confirm',
        cancel: 'cancel',
        recipientHasBeenDeleted: 'Recipient has been deleted',
        address: 'Address',
        city: 'City',
        country: 'Country',
        stateOrProvince: 'State or province',
        zipCode: 'ZIP Code',
        postalCode: 'Postal code',
        mcc: 'MCC',
        backButton: 'Back',
        delete: 'Delete',
        routingNumber: 'Routing number',
        transferType: 'Transfer type',
        email: 'Email address',
        mobile: 'Mobile number',
        addressLines: 'Address Lines',
        agentPostalCode: 'Bank postal code',
        bankAddressLine: 'Bank address line',
        bankName: 'Bank name',
        bankCountry: 'Bank country',
        bankCity: 'Bank city',
        bankStateOrProvince: 'Bank state or province'
      },
      edit: {
        title: 'Edit Recipient Details'
      },
      new: {
        title: 'Add New Recipient',
        baseInfo: 'Base Information',
        additionalInfo: 'Additional Information (Optional)',
        save: 'Save recipient',
        email: 'Email address',
        mobile: 'Mobile number',
        transferReference: 'Transfer reference',
        maxAmount: 'Maximum single transaction',
        success: {
          message: 'Your recipient {nickname} was successfully stored',
          returnToDashboard: 'Return to dashboard',
          accountNo: 'Account No',
          bankCode: 'Bank code'
        },
        paymentType: 'Payment type',
        address: 'Address Infomation',
        validation: {
          email: 'Invalid Email',
          alreadyExist: 'Recipient already exists.'
        },
        bankDetails: 'Bank details'
      }
    },
    employees: {
      list: {
        accountCreated: 'Account created',
        accountNo: 'Account No',
        addAccount: 'Add account',
        addCard: 'Add card',
        balance: 'Balance',
        cardCreated: 'Card created',
        cardStatus: 'Card status',
        createAccess: 'Create new user access',
        designation: 'Designation',
        name: 'Name',
        parentAccount: 'Parent account',
        skipThisStep: 'Skip this step',
        status: 'Status'
      },
      new: {
        accountUseToAllocate: 'Account you will use to allocate the money from',
        addressLine1: 'Address line 1',
        addressLine2: 'Address line 2',
        addressType: 'Address type',
        allocateFunds: 'Allocate funds',
        allTheFundsBeTransferred: 'All the funds of the user will be transferred back to primary account of the organization',
        cardProduct: 'Card product',
        cardType: 'Card type',
        city: 'City',
        continue: 'Continue',
        country: 'Country',
        createNewEmployee: 'Create new employee',
        currency: 'Currency',
        dateOfBirth: 'Date of birth',
        documentTypeReason: 'Reason for document type absence',
        documentNumber: 'Document number',
        documentType: 'Document type',
        email: 'Email',
        enterTheAmountToAllocate: 'Enter the amount you want to allocate to ',
        existingPerson: 'Existing person',
        firstName: 'First name',
        gender: 'Gender',
        header: 'Add New Employee',
        lastName: 'Last name',
        mobileNo: 'Mobile number',
        nameNewAccount: 'Name the new account',
        network: 'Network',
        newPerson: 'New person',
        position: 'Position',
        postalCode: 'Postal code',
        primaryAccount: 'Primary Account',
        save: 'Save Employee',
        selectAccount: 'Select account',
        selectAccountName: 'Select account name',
        selectAccountOrganizationPrimary: 'Select organization primary account for the new account creation',
        selectFromExistingUsers: 'Select from existing users',
        selectTypeOfCard: 'Select type of card',
        selectTypeOfEmployee: 'Select type of employee',
        skipAddingAccountForNewUser: 'Skip adding the account for new user',
        skipAddingCardForNewUser: 'Skip adding the card for new user',
        ssn: 'Social security number',
        state: 'State',
        successMessage: 'Employee created successfully',
        cardSuccessMessage: 'Card created successfully',
        fundsSuccessMessage: 'Funds allocated successfully',
        activateSuccessMessage: 'User was activated successfully',
        deactivateSuccessMessage: 'User was deactivated successfully',
        personalDetails: 'Personal details',
        primaryAccountDetails: 'Primary account details',
        productName: 'Product name',
        reason: 'Reason',
        reasonForDeactivation: 'Reason why {name} is going to be deactivated',
        designation: 'Designation',
        title: 'Title',
        userDetails: 'User details',
        yearsToExpire: 'Years to expire',
        virtual: 'Virtual',
        yes: 'Yes',
        youAreGoingToDeactivate: 'You are going to deactivate the following employee',
        no: 'No'
      },
      view: {
        cards: 'Cards',
        clientDetails: 'Client details',
        transactions: 'Transactions',
        primaryAddress: 'Primary address'
      }
    },
    registration: {
      cannotContinue: 'Finish',
      header: 'Registration',
      step: 'Step: {step}',
      backToLogin: 'Back to login page',
      noWorkflow: {
        oops: 'Oops...',
        message: 'We are currently unable to show you the registration form. This is usually because we are making changes on ourside. We appreciate your patience and understanding of this matter.'
      },
      accountTypeSelection: {
        header: 'Account type',
        personal: 'Personal',
        corporate: 'Corporate',
        welcomeMessage: 'Hi {name}',
        welcomeQuestion: 'which account would you like to open',
        backButton: 'Back'
      },
      customerCreation: {
        header: 'Get Started',
        fields: {
          firstName: {
            label: 'First name'
          },
          lastName: {
            label: 'Last name'
          },
          email: {
            label: 'Email address'
          },
          mobileNumber: {
            label: 'Mobile number'
          },
          password: {
            label: 'Choose a password'
          },
          repeatPassword: {
            label: 'Repeat the password'
          },
          consentMarketingEmails: {
            label: 'yes, send me news'
          },
          consentToAllDocuments: {
            label: 'By checking this box, I am acknowledging that I have read and agreed to the following:',
            termsOfService: 'Terms of Service',
            privacyPolicy: 'Privacy Policy',
            accountCardAgreement: 'Evolve\'s Business Account/Cardholder Agreement',
            electronicCommunicationsAgreement: 'Evolve\'s Electronic Communications Agreement'
          }
        },
        backButton: 'Back',
        nextButton: 'Next'
      },
      personalInformation: {
        header: 'Personal Information',
        fields: {
          firstName: {
            label: 'First name'
          },
          familyName: {
            label: 'Family name'
          },
          dateOfBirth: {
            label: 'Date of birth'
          },
          gender: {
            label: 'Gender'
          },
          nationality: {
            label: 'Nationality'
          },
          usCitizen: {
            label: 'US citizen (FATCA Compliance)'
          },
          termAgree: {
            label: 'I accept terms of use & privacy policy'
          }
        },
        backButton: 'Back',
        nextButton: 'Next'
      },
      companyInformation: {
        header: 'Corporate Information',
        fields: {
          name: {
            label: 'Company Name'
          },
          email: {
            label: 'Email Address'
          },
          notRegistered: {
            label: 'Company not registered'
          },
          legalEntity: {
            label: 'Legal Entity'
          },
          founded: {
            label: 'When Founded'
          },
          countryRegistration: {
            label: 'Country of Entity Registration'
          },
          mobileNumber: {
            label: 'Mobile Number'
          },
          taxIdentification: {
            label: 'Tax Identification'
          }
        },
        backButton: 'Back',
        nextButton: 'Next'
      },
      residenceAddress: {
        header: 'Address Of Residence',
        fields: {
          addressTypeId: {
            label: 'Address Type'
          },
          address1: {
            label: 'Address 1'
          },
          address2: {
            label: 'Address 2'
          },
          country: {
            label: 'Country'
          },
          zipCode: {
            label: 'Zip Code'
          },
          state: {
            label: 'State'
          },
          postal: {
            label: 'Postal Code'
          },
          city: {
            label: 'City'
          }
        },
        backButton: 'Back',
        nextButton: 'Next'
      },
      identityVerification: {
        header: 'Identity Verification',
        fields: {
          documentType: {
            label: 'Document Type'
          },
          documentKey: {
            label: 'Document Key',
            exist: 'Document key is already used'
          }
        },
        backButton: 'Back',
        nextButton: 'Next'
      },
      pinCreation: {
        header: 'Create security PIN',
        fields: {
          Pin: {
            label: 'PIN'
          },
          confirmPin: {
            label: 'Confirm PIN'
          }
        },
        backButton: 'Back',
        nextButton: 'Create security PIN'
      },
      cardSelection: {
        header: 'Card Selection',
        cardType: {
          classic: 'Classic',
          platinum: 'Platinum'
        },
        yourPin: 'Your PIN',
        changePinQuestion: 'Do you want to change the PIN?',
        changePinAnswer: 'Yes',
        fields: {
          pin: {
            label: 'PIN'
          },
          confirmPin: {
            label: 'Confirm PIN'
          }
        },
        agreeOrder: 'I agree and order',
        noCard: 'No, I don\'t need a card',
        pleaseSelectCard: 'Please select card type',
        pleaseSelectAccountText: 'Please select account type to connect with this card:',
        pelaseSelectAccount: 'Please select account type',
        backButton: 'Back'
      },
      ownershipInformation: {
        beneficialOwnerCertification: 'Beneficial Owner Certification',
        federalLaw: 'Federal Law requires you to identify at least one authorized signer and control person of the entity and all individuals that own at least 25%, whether directly or through another entity. Have you met this requirement?',
        iAmAn: 'I am a(n)',
        iAmAuthorizedSignerNote: 'I am an authorized signer, control person (Company Director or Individual with significant responsibility to control, manage or direct the entity), or owner at least 25% of the entity whether directly or through another entity.',
        gatherInfoAboutIndividuals: 'Ok, let’s gather some info about the individuals associated with the account',
        needAtLeastOneControl: 'You need to provide at least one control person of the entity!',
        needAtLeastOneSigner: 'You need to provide at least one authorized signer of the entity!',
        ok: 'Ok',
        pleaseCheckAllThatApplyForIndividual: 'Please check all that apply for the individual whose information you are adding',
        pleaseCheckAllThatApply: 'Please check all that apply',
        submit: 'Submit',
        thanksForSubmitting: 'Thank you for submitting. The individuals added will receive emails from us shortly to complete their verification by uploading an ID (unexpired government-issued identification evidencing nationality or residence and bearing a photograph or similar safeguard, such as a driver\'s license or passport)',
        totalOwnershipTooMuch: 'Total ownership share that you stated is more than 100%!'
      },
      confirmation: {
        thanks: 'Thanks ',
        verifyingId: 'we are now verifying your ID.',
        details: 'Thank you for opening a bank account with us. Your identity is now being verified. Once we will be finished we will let you know.'
      },
      backToSignIn: 'Back to sign-in',
      back: 'back',
      next: 'next',
      statics: {
        submitFinalConfirmation: {
          description: 'You currently applied for opening a business account through our bank app. If you want to confirm this application, please click the link below.'
        }
      }
    },
    users: {
      list: {
        fullName: 'Full name',
        createdAt: 'Created at',
        email: 'Email',
        phone: 'Phone',
        name: 'Name',
        noOfAssignedUsers: 'No of assigned users'
      },
      form: {
        new: {
          title: 'Add new user',
          save: 'Create user with access',
          success: 'User created successfully'
        },
        edit: {
          title: 'Edit user',
          save: 'Update user',
          success: 'User updated successfully'
        },
        userType: 'User type',
        roles: 'Roles',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        phone: 'Phone'
      }
    }
  },
  views: {
    error: {
      somethingwentwrong: 'Oops, something went wrong.',
      technicalDifficulties: 'We are experiencing some technical difficulties.',
      tryAgainLater: 'Please try again later.',
      goBack: 'Go back',
      cannotFetch: 'Cannot retrive data from {resourceName}',
      noAttachedClient: 'Login failed: you have no active client',
      clientIsNotActivated: 'Your client is not activated, please return here once you will receive the confirmation of your client activation'
    },
    login: {
      welcomeMessage: 'The smartest way to bank',
      OtpMessage: {
        header: '{info} {action}',
        info: 'just one more step,',
        action: 'confirm it is you!',
        description: 'To access web banking,',
        deliveryMethod: {
          email: 'enter token that was sent to your {emailAddress}',
          totpMobile: 'enter token from {twoFacetorApp} on your mobile device.'
        },
        emailAddress: 'email address',
        twoFacetorApp: 'two-factor app',
        configMfa: 'Alternatively you can now configure your {mfa} to log in',
        mfa: 'MFA',
        enableTOTPSuccessfully: 'Enable TOTP successfully! Please log in again!',
        no2fa: `Don't have your two-factor app with you?`
      },
      form: {
        fields: {
          username: {
            label: 'User Identifier',
            placeholder: 'jon-snow',
            validation: {
              required: 'User Identifier is required'
            }
          },
          password: {
            label: 'Password',
            placeholder: 'Enter 6 characters or more',
            validation: {
              required: 'Password is required',
              characterCount: 'Enter {charLength} characters or more'
            }
          },
          otp: {
            label: {
              token: 'Token',
              recoveryCode: 'Recovery code'
            },
            validation: {
              required: 'Token is required'
            }
          }
        },
        buttons: {
          signIn: 'Login',
          submitOtp: 'Confirm with token',
          confirm: 'Confirm',
          useRecoveryCode: 'Use recovery code',
          use2fa: 'Use 2FA token',
          useOtp: 'Use OTP token'
        }
      },
      links: {
        forgotPassword: 'Forgot password?',
        needHelp: 'Need help?',
        needHelpLink: 'Click here',
        noAccount: 'Don\'t have an account?',
        noAccountLink: 'Create one here',
        backToLogin: 'Back'
      },
      notifications: {
        successfulLogin: 'Login successful',
        successfulLogout: 'Logout successful',
        accountNotReady: 'Your account is not ready, please try again later'
      },
      resetPassword: {
        success: {
          header: 'Your password has been changed successfully',
          description: 'You can now sign in to web banking using your new password',
          login: 'Log in to banking'
        },
        header: 'Your password has expired',
        description: 'To continue with web banking you will have to define your new password.',
        newPassword: 'Enter your new password',
        currentPassword: 'Enter your current password',
        confirmPassword: 'Confirm your new password',
        fieldRequired: 'Field is required',
        notMatch: 'Password does not match',
        changePassword: 'Change password'
      },
      forgotPassword: {
        header: 'Set your new password',
        description: 'To continue with web banking you will have to define your new password.',
        newPassword: 'Enter your new password',
        confirmPassword: 'Confirm your new password',
        fieldRequired: 'Field is required',
        changePassword: 'Change password',
        notMatch: 'Password do not match',
        backToLogin: 'Back',
        request: {
          header: 'Request password reset',
          description: 'Enter your user identifier used to login to web banking',
          user: 'Enter your user identifier',
          submit: 'Submit',
          backToLogin: 'Back',
          success: {
            title: 'Password reset was requested',
            reset: {
              description: 'It has been requested to reset your password, please follow the instructions sent to your {emailAddress}',
              deliveryMethod: {
                email: 'email address'
              }
            }
          },
          required: 'Field is required',
          error: 'Service is temporarily unavailable, please try again later'
        }
      },
      acceptTermsConditions: {
        description: `To proceed and make full use of your account, it is mandatory that you accept the Terms and Conditions associated with our services.
        These Terms and Conditions serve as a legal agreement between you and our platform, outlining the rules and guidelines for using our services. By accepting these Terms and Conditions, you acknowledge that you understand and agree to abide by these guidelines.`,
        back: 'Back',
        accept: 'I do accept {0} terms and conditions'
      }
    },
    dashboard: {
      header: 'Dashboard',
      financialBreakdown: {
        title: '{monthlyOrWeekly} breakdown',
        titleOptions: {
          weekly: 'weekly',
          monthly: 'monthly'
        },
        settings: {
          showWeekly: 'show weekly',
          showMonthly: 'show monthly'
        }
      },
      balance: {
        title: 'Balance'
      },
      accounts: {
        add: 'Add account',
        title: 'Accounts',
        totalBalance: 'Total Balance',
        availableBalance: 'Available balance',
        primaryAccountTooltip: 'Primary accounts can hold the subsidiary accounts',
        unallocatedBalance: 'Unallocated balance',
        seeAllAccounts: 'See all accounts'
      },
      loans: {
        accountNumber: 'Account number'
      },
      lastTransactions: {
        title: 'Last transactions'
      },
      standingOrders: {
        title: 'Standing orders'
      },
      frequentTransfers: {
        title: 'Most Frequent Transfers'
      },
      analytics: {
        title: 'Analytics'
      },
      cards: {
        title: 'Cards'
      }
    },
    accounts: {
      header: 'Accounts',
      goToAccounts: 'Go to accounts',
      closeDetails: 'Close details',
      details: {
        header: 'Account Details',
        backButton: 'Back',
        accountStatement: 'Account Statement',
        deactivate: 'Deactivate',
        accountNumber: 'Account Number'
      },
      loan: {
        header: 'Loans'
      },
      primaryAccount: 'Primary accounts',
      subsidiaryAccount: 'Subsidiary accounts',
      mainAccount: 'Main accounts',
      account: 'Accounts',
      allAccounts: 'All accounts',
      operationalAccounts: 'Operational accounts',
      departmentAccounts: 'Department accounts',
      employeeAccounts: 'Employee accounts'
    },
    recipients: {
      header: 'External Recipients',
      searchPlaceholder: 'Search by nickname or account number.',
      add: 'Add recipient',
      new: {
        header: 'Create Recipient',
        title: 'Enter Recipient Details '
      }
    },
    employees: {
      accountSettings: 'Account settings',
      bulkUpload: 'Bulk upload',
      header: 'Employees',
      searchPlaceholder: 'Search by name or account number.',
      add: 'Add employee',
      addPhoto: 'Add employee photo',
      addCard: 'Order card',
      cardDetails: 'Card details',
      fund: 'Fund',
      activate: 'Activate',
      deactivate: 'Deactivate',
      noPrimaryAccount: 'Primary accounts are required to continue with adding employees'
    },
    clients: {
      admin: 'Admin',
      allUsers: 'All users',
      avatar: 'Avatar',
      cardProducts: 'Card products',
      classification: 'Classification',
      confirmUploaded: 'Confirm uploaded people ({count})',
      createNewUserAccess: 'Create new user access',
      dictionaries: 'Dictionaries',
      documentTypes: 'Document types',
      downloadTemplateNote: 'Download the template for uploading the batch of users. Use the dictionaries on the right in order to provide correct values for your user access.',
      downloadXLSFile: 'Download XLS file',
      employee: 'Employee',
      genders: 'Genders',
      id: 'Id',
      invalidUsers: '{count} uploaded people have incorrect data',
      manageCard: 'Manage card',
      manageUsers: 'Manage users',
      numOfActiveCards: 'No of active cards',
      primaryAccounts: 'Primary accounts',
      rowValidationError: 'Row {position} in your bulk upload file contains the following errors',
      searchPlaceholder: 'Search by name',
      selectAll: 'Select all uploaded people',
      successUpload: 'Successfully uploaded {count} users',
      userDetails: 'User details'
    },
    transactions: {
      header: 'Transactions',
      search: 'Search',
      accountsFilter: {
        filter: 'Filter by accounts'
      }
    },
    users: {
      header: 'Account Management',
      add: 'Create new user',
      users: {
        users: 'Users'
      },
      roles: {
        roles: 'Roles'
      }
    },
    transfers: {
      header: 'Create Transfer',
      recipientTitle: 'Browse recipients and history',
      confirmation: {
        title: 'Transfer Confirmation',
        success: 'Transfer Success'
      }
    },
    transfer: {
      header: 'Create new transfer',
      button: 'Create new transfer'
    },
    cards: {
      header: 'Card Center',
      noCard: {
        title: 'You don\'t have any cards',
        description: 'Order your first card, and start using all benefits that it gives you',
        benefits: {
          contactlessPayments: 'Contactless payments',
          onlinePayments: 'Secure online transactions',
          geoSupport: 'GEO location support'
        },
        selectCard: 'select your card'
      }
    },
    settings: {
      title: 'User Settings',
      loginInformation: 'Login Information',
      header: 'Settings',
      fullName: 'Full name',
      userName: 'User name',
      email: 'Email address',
      password: 'Password',
      changePassword: 'Change password',
      tabs: {
        general: 'General',
        notification: 'Notification',
        security: 'Security',
        profile: 'Profile'
      }
    },
    communications: {
      header: 'Messages'
    },
    support: {
      header: 'Support'
    },
    currentPassword: {
      notMatch: 'Unable to reset the password, please follow our password policy'
    },
    errorCodes: {
      notMatch: 'Your current password is incorrect',
      differentNewPassword: 'New password must be different from current password',
      invalidToken: 'Your reset password token is invalid',
      tokenExpired: 'Your reset password token has expired',
      error: 'Error',
      noActiveSavings: 'User has no active savings account',
      notEnoughPermissions: 'Not enough permissions to access the resource',
    }
  }
}
