import { withAuth } from '@/api/request.js'

const request = withAuth()

export default ({ clientId }) => {
  return request({
    data: {
      query: `
        {
         getClientDetails(clientId: ${clientId}) {
           id
           dateOfBirth
           emailAddress
           mobileNo
           mobileCountryCode
           avatarUrl
           clientTypes {
			id
			label
			description
			identifier
		    position
		   }
           accounts {
            id
            accountNo
            parentAccountId
            savingsProductName
            savingsProductId
            availableBalance
            mobileNo
            nickName
            designation
            remainingAllocatedFunds
            savingsAmountOnHold
            currency
            client {
              id
            }
           }
		   addresses {
			address {
			  id
			  city
			  addressLine1
			  addressLine2
			  addressLine3
			  country {
			    id
			    label
			    identifier
			    description
			  }
			}
			addressType {
			  id
			  label
			  description
			  identifier
			}
		   }
		   cards {
		    id
		    productName
		    expiresOn
		    network
		    status
		    primaryAccountNumber
		    virtual
            digitalFirst
		   }
		   firstName
		   lastName
		   identifiers {
		    id
		    documentKey
		    documentType {
		      id
		      identifier
		      description
		    }
		   }
		   parentId
		   status
		   submittedOn
		   users {
		    id
		    firstName
		    lastName
		    mobile
		   }	
	      } 
	    }
      `
    }
  })
}
