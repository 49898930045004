export const clientBulkUploadAdapter = (payload) => {
  let response = '['
  payload.forEach(item => {
    response += `
      {
	    firstname: "${item.firstname}"
        lastname: "${item.lastname}"
        emailAddress: "${item.emailAddress}"
        dateOfBirth: "${item.dateOfBirth}"
        designation: "${item.designation}"
        locale: "en"
        dateFormat: "yyyy-MM-dd"
        mobileCountryCode: "${item.mobileCountryCode}"
        mobileNo: "${item.mobileNo}"
        genderId: ${item.genderId}
        roles: [${item.role}]
        ${item.primaryAccountId ? `primaryAccountId: ${item.primaryAccountId}` : ''}
        ${item.cardProductId ? `cardProductId: ${item.cardProductId}` : ''}
        officeId: ${item.officeId}
        identifiers: [
           {
              documentTypeId: ${item.documentType}
              documentKey: "${item.documentNo}"
              status: Active
           }
        ]
      }
    `
  })
  response += ']'
  return response
}
