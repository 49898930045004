import accounts from './accounts'
import application from './application'
import recipients from './recipients'
import cards from './cards'
import user from './user'
import users from './users'
import transactions from './transactions'
import communications from './communications'
import localSettings from './localSettings'
import views from './views'
import registration from './registration'
import support from './support'
import organization from './organization'
import preferences from './preferences'
import employees from './employees'
import clients from './clients'

export default {
  accounts,
  application,
  recipients,
  cards,
  user,
  users,
  transactions,
  communications,
  localSettings,
  views,
  registration,
  support,
  organization,
  preferences,
  employees,
  clients
}
