import { withAuth } from '@/api/request.js'

const request = withAuth()

export const getSavingProducts = () => {
  return request({
    data: {
      query: `
        {
          SavingsProducts {
            select {
              id
              name
              currency {
                code
              }
            }
          }
        }
      `
    }
  })
}

export const createSavingAccount = ({productId, nickName, isPrimaryAccount, allowPrepaidCard}) => {

  return request({
    data: {
      query: `
        mutation{
          createClientEntitySavingsAccount(
            input: {
              productId: ${productId}
              nickName: "${nickName}"
              isPrime: ${isPrimaryAccount}
              isPrepaid: ${allowPrepaidCard}
            }
          )
        }
      `
    }
  })
}

export const updateAccountNickname = (accountId, nickName) => {
  return request({
    data: {
      query: `
        mutation {
          setNickName(accountId: ${accountId}, input: {
            nickName: "${nickName}"
          })
        }
      `
    }
  })
}