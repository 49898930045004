<template>
  <div
    v-if="$appConfig.enabled('registration')"
    class="pt-6 text-center"
  >
    {{ $t('views.login.links.noAccount') }}

    <a
      v-if="isCheqlyProd"
      href="https://signup.cheqly.com/"
    >
      {{ $t('views.login.links.noAccountLink') }}
    </a>
    <router-link
      v-else
      to="registration"
    >
      {{ $t('views.login.links.noAccountLink') }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'RegistrationLink',
  computed: {
    isCheqlyProd() {
      return process.env.VUE_APP_API_URL === 'https://api.app.cheqly.com'
    }
  }
}
</script>
