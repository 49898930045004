import colors from 'vuetify/es5/util/colors.js'

const dark = {
  primary: '#4683F8',
  secondary: colors.teal.base,
  tertiary: colors.red.lighten5,
  quaternary: '#50535a',
  quinary: colors.grey.darken1,
  accent: colors.pink.base,
  success: '#A6DE7A',
  error: colors.pink.lighten1,
  background: '#000',
  surface: '#1B1D1F',
  surface2: '#4b4e57',
  // fade: colors.grey.darken3,
  chipback: '#C5CAE9',
  fade: '#26292B',
  errorBackground: '#302028',
  alert: '#FF8D8D'
}

const light = {
  primary: colors.lightBlue.darken1,
  secondary: colors.teal.base,
  tertiary: colors.red.lighten5,
  quaternary: colors.grey.lighten3,
  quinary: colors.grey.lighten2,
  accent: colors.pink.base,
  success: '#4CAF50',
  error: colors.pink.darken3,
  background: '#fafafa',
  surface: '#ffffff',
  surface2: '#f2f2f2',
  fade: colors.grey.lighten4,
  chipback: '#C5CAE9',
  errorBackground: '#F1E0E6',
  alert: '#FF8D8D'
}

export {
  dark,
  light
}
